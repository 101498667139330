import React from "react"
import cx from "classnames"

import { JobApplyForm } from "../../components/job-apply-form-modal"
import SEO from "../../components/seo"
import styles from "./job.module.css"
import styles2 from "./jobs.module.css"
import teamImg2 from "./team-2.jpg"

const JobPage = () => {
  return (
    <>
      <SEO title="Sales tým leader" />

      <div className={styles.bgJob}>
        <div className={styles.job}>
          <img
            src={teamImg2}
            className={cx(styles.teamImg, styles2.teamImg)}
            alt="team image"
          />

          <h1 className={styles.textHeader}>Sales tým leader</h1>

          <p className={styles.text}>
            Jeden z nejrychleji rostoucích Foodtech startupů v Česku právě
            rozšiřuje svůj <strong>obchodní tým!</strong>
          </p>

          <p className={styles.text}>
            Vyrábíme stovky tisíc jídel měsíčně a jsme největší společnost v ČR,
            která poskytuje personalizované jídelníčky s kompletním servisem a
            dovozem až domů.
          </p>

          <p className={styles.text}>
            Našimi zákazníky jsou všichni, komu záleží na tom, co jí: od mladých
            profesionálů, přes sportovce až k seniorům. Každému pomáháme
            dosáhnout jejich cíle ať už je to cítit se lépe, redukovat váhu,
            nebo zlepšit svůj zdravotní stav.
          </p>

          <p className={styles.text}>
            Chceš být součástí další success story českého startupového světa?
            Baví Tě zdravý životní styl? Sportuješ?
          </p>

          <p className={styles.text}>
            Staň se součástí našeho nadšeného týmu a dělej práci, která Ti bude
            dávat smysl!
          </p>

          <h3 className={styles.textSubHeader}>Co u nás budeš dělat?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              povedeš malý sales tým orientovaný na koncového zákazníka
            </li>
            <li className={styles.newList}>
              budeš šéfem (šéfovou), ale i kamarádem
            </li>
            <li className={styles.newList}>
              vylepšíš fungování týmu a vybereš si nové kolegy
            </li>
            <li className={styles.newList}>
              stmelíš kolektiv a budeš motivovat
            </li>
            <li className={styles.newList}>budeš předvídat problémy</li>
            <li className={styles.newList}>
              naladíš nováčky na stejnou vlnu se stávající partou
            </li>
            <li className={styles.newList}>půjdeš příkladem</li>
          </ul>

          <h3 className={styles.textSubHeader}>Co od tebe očekáváme?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Zkušenosti:
              <ul className={styles.textUl}>
                <li className={styles.newList}>s vedením obchodního týmu,</li>
                <li className={styles.newList}>
                  s náborem a onboardingem nových obchodníků
                </li>
                <li className={styles.newList}>
                  s komunikací se zákazníkem po telefonu,
                </li>
                <li className={styles.newList}>
                  s analytikou obchodního procesu
                </li>
              </ul>
            </li>
            <li className={styles.newList}>Entuziasmus</li>
            <li className={styles.newList}>Lásku ke zdravému jídlu</li>
            <li className={styles.newList}>
              Češtinu úrovně rodilého mluvčího (bez přízvuku), komunikativní
              angličtinu, výhodou je němčina.
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Co získáš od nás?</h3>
          <ul className={styles.textUl}>
            <li className={styles.newList}>
              Možnost být součástí úspěchu ambiciózní značky
            </li>
            <li className={styles.newList}>
              Kvalitní onboarding = nehodíme Tě do vody a plav ☺
            </li>
            <li className={styles.newList}>
              Finanční ohodnocení odpovídající Tvým zkušenostem + měsíční bonus
            </li>
            <li className={styles.newList}>
              Vzdělávací kurzy a konzultace s předními odborníky v oboru
            </li>
            <li className={styles.newList}>Firemní obědy zdarma</li>
            <li className={styles.newList}>Multisportku</li>
            <li className={styles.newList}>
              Moderní kanceláře v pulsujícím srdci Karlína
            </li>
          </ul>

          <h3 className={styles.textSubHeader}>Těšíme se na Tebe!</h3>
          <h3 className={styles.textSubHeader}>Kontakty:</h3>
          <a className={styles.jobsEmail} href="mailto:ana@nutritionpro.cz">
            jobs@nutritionpro.cz
          </a>
          {/* <a className={styles.jobsEmail} href="tel:+420608068463">
            +420 608 068 463
          </a> */}
          <br />
          <br />
          <a className={styles.backButton} href="/jobs">
            Zpět
          </a>
          {/* <div style={{ marginTop: "40px" }}>
            <JobApplyForm formName="JobApplyForm_marketingManager" />
          </div> */}
        </div>
      </div>
    </>
  )
}

export default JobPage
